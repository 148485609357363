import { useState, useEffect } from "react";

import "./home.css";

import Header from "../../components/Header";
import axios from "axios";
import appstorelogo from "../../assests/images/website/appstore.svg";
import Toaster from "../Toast";

import { ErrorToast, SuccesToast } from "../Toast/message";

const Home = () => {
  const [value, setValue] = useState("");
  const [mail, setmail] = useState("");
  const [confirm, setconfirm] = useState(false);

  let params = new URLSearchParams(window.location.search);
  let ref = params.get("ref") || "";
  let campaign = params.get("campaign") || "";
  function setCookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
  if (ref) {
    setCookie("ref", ref, 30); // Set the cookie to expire in 30 days
  }
  if (campaign) {
    setCookie("campaign", campaign, 30); // Set the cookie to expire in 30 days
  }

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };

  const newsletter = async (event) => {
    try {
      const response = await axios.post(
        "https://accountapi-dev.bettdraft.com:5000/email/newletter",
        {
          email: mail,
          state: value,
        }
      );
      if (response.data.status == "success") {
        SuccesToast(
          `Thank you for subscribing to our newsletter! We're excited to have you join our community.`
        );
        setmail("");
        setconfirm(false);
      }
    } catch (error) {
      console.error(error); // Handle any errors here
    }
  };

  const data = `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`;

  console.log(value);

  const submitRequest = () => {
    if (mail == "") {
      ErrorToast("Please Enter Email address");
    } else if (confirm == false) {
      ErrorToast("Please accept the Terms and Conditions to proceed");
    } else {
      newsletter();
    }
  };

  return (
    <>
      <Toaster />
      <div>
        <Header />

        <section className="sec-padd banner-section">
          <div className="container">
            <h1 className="title-p mb-0 font-bold">
              DAILY FANTASY WITH THE BEST PAYOUTS
            </h1>
            <span className="big-font text-center">
              {/* Get <span>$10 FREE</span> play{" "} */}
              Get UP TO $100
            </span>
            {/* <h3 className="plustext">Plus</h3>
            <h3>
              <span>UP TO $100 </span>
            </h3> */}
            <h4>First Deposit Match</h4>
            <div className="flex-center mt-5">
              <a
                className="btn-c"
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/join-now"
                }
              >
                Play Now
              </a>
              <a
                className="btn-social"
                href={"https://play.spinsagacasino.com/"
                }
                target="_blank" 
                rel="noopener noreferrer"
              >
                Social Casino
              </a>
            </div>
          </div>
        </section>

        <section className="sec-padd bg-pattern ">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-md-6">
                <h2 className="medium-heading mt-2 mb-4">
                  Exclusively <span>on BettDraft</span>
                </h2>
                <p className="medium-p mb-0">Play for up to </p>
                <img
                  className="ms-4"
                  src={require("../../assests/images/website/125x.webp")}
                  alt="BettDraft 125X Payout"
                />
                <p className="medium-p mb-0 end-text">your entry fee</p>

                <p className="second-p mb-0">
                  Pick your favorite players to get started
                </p>
                <img
                  src={require("../../assests/images/website/group-img.webp")}
                  alt="BettDraft Player's Headshot"
                />
                <div className="flex-center mt-2">
                  <a
                    className="btn-c"
                    href={
                      ref && campaign
                        ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                        : "https://dfs.bettdraft.com/join-now"
                    }
                  >
                    Play Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-barcode bg-pattern sec-padd">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 ">
                <div className="newSection">
                  <img
                    src={require("../../assests/images/website/Phone.png")}
                    alt="BettDraft NBA"
                  />
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="newSection">
                  <div className="row align-bottom">
                    <div className="row justify-content-center">
                      {/* <img src={require("../../assests/images/website/bettdraftqr.png")} alt="" className="barcodeImg" /> */}

                      {/* <a
                        href="https://play.google.com/store/apps/details?id=com.bettdraft"
                        rel="nofollow"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "25px",
                          marginTop: "30px",
                        }}
                      >
                        <img
                          src={require("../../assests/images/website/playstoremain.png")}
                          alt=""
                          style={{ height: "108px" }}
                        />
                      </a> */}

                      <a
                        href="https://apps.apple.com/us/app/bettdraft-fantasy-sports-game/id6467048047"
                        rel="nofollow"
                        className="appstoreImg"
                        // style={{ display: "flex", justifyContent: "center",marginTop:'10rem' }}
                      >
                        <img
                          src={appstorelogo}
                          alt=""
                          style={{ height: "80px" }}
                        />
                      </a>
                      {/* <p className="flex-p mt-2"><span>Got Android?</span> <a href={androidapp}>CLICK HERE</a> to download the APP.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-map1 sec-padd">
          <div className="container">
            <h2 className="c-heading text-center">BettDraft is available in</h2>
            <p className="text-center text-p state">
              You can now play in AK, CA, DC, GA, IL, KS, KY, MN, ND, NE, NM,
              OK, OR, RI, SC, SD, TX, UT, WI & VT.
            </p>
            <img
              src={require("../../assests/images/website/bettdraftmap-copy.webp")}
              alt="BettDraft Map"
              style={{ width: "100%" }}
            />
            <div className="flex-center mt-2 mb-2">
              <a
                className="btn-c"
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/join-now"
                }
              >
                Play Now
              </a>
            </div>
            {/* <p className="small-p w-100">PLAY DAILY FANTASY AT BETTDRAFT TODAY!</p> */}
          </div>
        </section>

        <footer className="sec-padd bg-gray">
          <div className="container">
            <div className="row  gy-3">
              <div className="col-md-3">
                <a
                  href={
                    ref && campaign
                      ? `${`https://dfs.bettdraft.com?ref=${ref}&campaign=${campaign}`}`
                      : "https://dfs.bettdraft.com"
                  }
                >
                  <img
                    className="mb-3"
                    src={require("../../assests/images/website/logo.png")}
                    alt="BettDraft Logo"
                  />
                </a>

                <div className="flex-footer">
                  <a
                    href="https://apps.apple.com/us/app/bettdraft-fantasy-sports-game/id6467048047"
                    rel="nofollow"
                    style={{
                      textDecoration: "none",
                      height: "45px",
                      width: "100%",
                      marginTop: "-12px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={appstorelogo} />
                  </a>
                  {/* <a
                    style={{
                      textDecoration: "none",
                      height: "45px",
                      width: "100%",
                      marginTop: "-12px",
                      cursor: "pointer",
                    }}
                    href="https://play.google.com/store/apps/details?id=com.bettdraft"
                    target="a_blank"
                  >
                    <img
                      src={require("../../assests/images/website/playstoremain.png")}
                      style={{ height: "65px", width: "100%" }}
                    />
                  </a> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <h5 className="footer-heading">SUPPORT</h5>
                    <ul>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/contact-us?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/contact-us"
                          }
                        >
                          Contact us
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/how-to-play?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/how-to-play"
                          }
                        >
                          How to play
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/faq?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/faq"
                          }
                        >
                          FAQs
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <h6 className="footer-heading">PLAYING AT BETTDRAFT</h6>
                    <ul className="ul-col">
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/about-us?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/about-us"
                          }
                        >
                          About Us
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/termsconditions?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/termsconditions"
                          }
                        >
                          Terms and Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/privacy-policy?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/privacy-policy"
                          }
                        >
                          Privacy Policy
                        </a>
                      </li>

                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/house-rules?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/house-rules"
                          }
                        >
                          House Rules
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/responsible-gaming?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/responsible-gaming"
                          }
                        >
                          Responsible Gaming
                        </a>
                      </li>
                      <li>
                        <a href={"https://play.spinsagacasino.com"}
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          Social Casino
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="flex-footer footer-social">
                  <a href="https://www.instagram.com/bettdraft/" rel="nofollow">
                    {" "}
                    <img
                      src={require("../../assests/images/website/Instagram.png")}
                    />
                  </a>
                  <a href="https://www.facebook.com/bettdraft" rel="nofollow">
                    {" "}
                    <img
                      src={require("../../assests/images/website/Facebook.png")}
                    />{" "}
                  </a>
                  <a href="https://twitter.com/BettDraft" rel="nofollow">
                    {" "}
                    <img
                      src={require("../../assests/images/website/Twitter.png")}
                    />
                  </a>
                </div>
                {/* <div className="flex-center mt-2 mb-2">
                  <a className="btn-social" href={"https://play.spinsagacasino.com"}>
                   Social Casino
                  </a>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="copy-p text-center mb-0">
                  © BettDraft All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
