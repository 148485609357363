import React from "react";
import "./header.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";


const Header = () => {
  let params = new URLSearchParams(window.location.search);
  let ref = params.get("ref") || "";
  let campaign = params.get("campaign") || "";
  function setCookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
  if (ref) {
    setCookie("ref", ref, 30); // Set the cookie to expire in 30 days
  }
  if (campaign) {
    setCookie("campaign", campaign, 30); // Set the cookie to expire in 30 days
  }

  return (
    <div className="sticky-top">
      <Navbar collapseOnSelect expand="lg" className=" custom-nav">
        <Container>
          <Navbar.Brand>
            <a
              href={
                ref && campaign
                  ? `${`https://dfs.bettdraft.com?ref=${ref}&campaign=${campaign}`}`
                  : "https://dfs.bettdraft.com"
              }
            >
              <img
                src={require("../../assests/images/website/bdlogo.png")}
                style={{ height: "44px", width: "240px" }}
              />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <a
                style={{ textDecoration: "none" }}
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com"
                }
              >
                Today’s Games
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="https://blog.bettdraft.com/"
              >
                BD Hub
              </a>
              <a
                style={{ textDecoration: "none" }}
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/how-to-play?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/how-to-play"
                }
              >
                How To Play
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="https://apps.apple.com/us/app/bettdraft-fantasy-sports-game/id6467048047"
                download="BettDraft"
              >
                Download App
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="https://play.spinsagacasino.com/"
                target="_blank" 
                rel="noopener noreferrer"
              >
                Social Casino 
              </a>
            </Nav>
            <div className="button-navbar">
              <a
                className="h-button green-btn"
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/join-now"
                }
              >
                Sign Up
              </a>
              <a
                className="h-button gray-btn"
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/login?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/login"
                }
              >
                Log In
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
